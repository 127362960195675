import {
  BETKWIFF_DOMAIN,
  BETKWIFF_PROD,
  CASINO,
  CASINO_BETKWIFF_DOMAIN,
  CASINO_BETKWIFF_PROD,
  CASINO_KWIFF_DOMAIN,
  CASINO_KWIFF_PROD,
  KWIFF_DOMAIN,
  KWIFF_PROD,
} from '../constants';
import { CookieType } from '../data-types/cookies/cookiesEnums';
import { UrlSearchParamType } from '../data-types/url/urlEnums';
import { AcceptedCountries } from '../enums';
import { store } from '../state/store';
import { getCookie } from './cookies';
import { getPostHogDistinctId, getPostHogSessionId } from './posthog';

export const getWebAppOriginReduxValue = () => store.getState().webAppOrigin;

const tryToAddBTagSearchParams = (url: string, bTag: string | undefined) => {
  let baseUrl = url;

  if (bTag) {
    const siteId = getCookie(CookieType.SITE_ID);
    const aId = getCookie(CookieType.A_ID);
    const bannerId = getCookie(CookieType.BANNER_ID);

    baseUrl = `${baseUrl}/?${UrlSearchParamType.BTAG}=${bTag}`;

    if (siteId) {
      baseUrl = `${baseUrl}&${UrlSearchParamType.SITE_ID}=${siteId}`;
    }
    if (aId) {
      baseUrl = `${baseUrl}&${UrlSearchParamType.A_ID}=${aId}`;
    }
    if (bannerId) {
      baseUrl = `${baseUrl}&${UrlSearchParamType.BANNER_ID}=${bannerId}`;
    }
  }

  return baseUrl;
};

const tryToAddAffIdSearchParams = (url: string, bTag: string | undefined, affId: string | undefined) => {
  let baseUrl = url;

  if (bTag && affId) {
    baseUrl = `${baseUrl}&${UrlSearchParamType.AFF_ID}=${affId}`;
  } else if (!bTag && affId) {
    baseUrl = `${baseUrl}/?${UrlSearchParamType.AFF_ID}=${affId}`;
  }

  return baseUrl;
};

const tryToAddSourceIdSearchParams = (url: string, bTag: string | undefined, affId: string | undefined, sourceId: string | undefined) => {
  let baseUrl = url;

  if ((bTag || affId) && sourceId) {
    baseUrl = `${baseUrl}&${UrlSearchParamType.SOURCE_ID}=${sourceId}`;
  } else if (!bTag && !affId && sourceId) {
    baseUrl = `${baseUrl}/?${UrlSearchParamType.SOURCE_ID}=${sourceId}`;
  }

  return baseUrl;
};

const addSignupSearchParams = (url: string, otherParamsExist: boolean) => {
  let baseUrl = url;

  if (otherParamsExist) {
    baseUrl = `${baseUrl}&${UrlSearchParamType.SIGNUP}=true`;
  } else {
    baseUrl = `${baseUrl}/?${UrlSearchParamType.SIGNUP}=true`;
  }

  return baseUrl;
};

const addCasinoParameterIfNeeded = (defaultUrl: string, otherParamsExist: boolean) => {
  const { isCasinoDomain } = store.getState();

  let url = defaultUrl;
  if (!otherParamsExist && isCasinoDomain) {
    url = `${url}/?${UrlSearchParamType.CASINO_FIRST}=true`;
  } else if (otherParamsExist && isCasinoDomain) {
    url = `${url}&${UrlSearchParamType.CASINO_FIRST}=true`;
  }
  return url;
};

export const generateHomeUrl = () => {
  const bTag = getCookie(CookieType.BTAG);
  const affId = getCookie(CookieType.AFF_ID);
  const sourceId = getCookie(CookieType.SOURCE_ID);

  let url = getWebAppOriginReduxValue();
  url = tryToAddBTagSearchParams(url, bTag);
  url = tryToAddAffIdSearchParams(url, bTag, affId);
  url = tryToAddSourceIdSearchParams(url, bTag, affId, sourceId);

  const otherSearchParamsHaveBeenAdded = !!bTag || !!affId || !!sourceId;
  url = addCasinoParameterIfNeeded(url, otherSearchParamsHaveBeenAdded);
  return url;
};

export const generateSignUpUrl = (path = '') => {
  const bTag = getCookie(CookieType.BTAG);
  const affId = getCookie(CookieType.AFF_ID);
  const sourceId = getCookie(CookieType.SOURCE_ID);

  const env = getWebAppOriginReduxValue();
  let url = `${env}${path}`;
  url = tryToAddBTagSearchParams(url, bTag);
  url = tryToAddAffIdSearchParams(url, bTag, affId);
  url = tryToAddSourceIdSearchParams(url, bTag, affId, sourceId);

  const otherSearchParamsHaveBeenAdded = !!bTag || !!affId || !!sourceId;
  url = addSignupSearchParams(url, otherSearchParamsHaveBeenAdded);
  url = addCasinoParameterIfNeeded(url, true);

  url = tryToAppendPostHogIdentifiersToURL(url);

  return url;
};

export const generateLogInUrl = () => {
  const bTag = getCookie(CookieType.BTAG);
  const affId = getCookie(CookieType.AFF_ID);
  const sourceId = getCookie(CookieType.SOURCE_ID);

  const env = getWebAppOriginReduxValue();
  let url = `${env}/login`;
  url = tryToAddBTagSearchParams(url, bTag);
  url = tryToAddAffIdSearchParams(url, bTag, affId);
  url = tryToAddSourceIdSearchParams(url, bTag, affId, sourceId);

  const otherSearchParamsHaveBeenAdded = !!bTag || !!affId || !!sourceId;
  url = addCasinoParameterIfNeeded(url, otherSearchParamsHaveBeenAdded);

  url = tryToAppendPostHogIdentifiersToURL(url);

  return url;
};

export const checkIfIsCasinoDomain = () => {
  return window.location.hostname.includes(CASINO);
};

export const checkIfIsBetkwiffDomain = () => {
  return window.location.hostname.includes(BETKWIFF_DOMAIN);
};

const getWebAppOriginDev = (isBetkwiffDomain: boolean, isCasinoDomain: boolean) => {
  let fallbackEnv = '';
  if (process.env.REACT_APP_ENV && isBetkwiffDomain && !isCasinoDomain) {
    fallbackEnv = process.env.REACT_APP_ENV.replace(KWIFF_DOMAIN, BETKWIFF_DOMAIN);
  } else if (process.env.REACT_APP_ENV && !isBetkwiffDomain && !isCasinoDomain) {
    fallbackEnv = process.env.REACT_APP_ENV;
  } else if (process.env.REACT_APP_ENV && isBetkwiffDomain && isCasinoDomain) {
    fallbackEnv = process.env.REACT_APP_ENV.replace(KWIFF_DOMAIN, BETKWIFF_DOMAIN).replace('//', '//casino.');
  } else if (process.env.REACT_APP_ENV && !isBetkwiffDomain && isCasinoDomain) {
    fallbackEnv = process.env.REACT_APP_ENV.replace('//', '//casino.');
  } else {
    fallbackEnv = KWIFF_PROD;
  }

  return fallbackEnv;
};

const getWebAppOriginProd = (isBetkwiffDomain: boolean, isCasinoDomain: boolean) => {
  let fallbackEnv = '';
  if (process.env.REACT_APP_ENV && isBetkwiffDomain && !isCasinoDomain) {
    fallbackEnv = process.env.REACT_APP_ENV.replace(KWIFF_DOMAIN, BETKWIFF_DOMAIN);
  } else if (process.env.REACT_APP_ENV && isBetkwiffDomain && isCasinoDomain) {
    fallbackEnv = process.env.REACT_APP_ENV.replace(KWIFF_DOMAIN, CASINO_BETKWIFF_DOMAIN);
  } else if (process.env.REACT_APP_ENV && !isBetkwiffDomain && !isCasinoDomain) {
    fallbackEnv = process.env.REACT_APP_ENV;
  } else if (process.env.REACT_APP_ENV && !isBetkwiffDomain && isCasinoDomain) {
    fallbackEnv = process.env.REACT_APP_ENV.replace(KWIFF_DOMAIN, CASINO_KWIFF_DOMAIN);
  } else if (isBetkwiffDomain && !isCasinoDomain) {
    fallbackEnv = BETKWIFF_PROD;
  } else if (isBetkwiffDomain && isCasinoDomain) {
    fallbackEnv = CASINO_BETKWIFF_PROD;
  } else if (!isBetkwiffDomain && isCasinoDomain) {
    fallbackEnv = CASINO_KWIFF_PROD;
  } else {
    fallbackEnv = KWIFF_PROD;
  }

  return fallbackEnv;
};

export const getWebAppOrigin = (isBetkwiffDomain: boolean, isCasinoDomain: boolean) => {
  const { geoLocation } = store.getState();
  const fallbackOrigin = window.location.origin.endsWith('.com')
    ? getWebAppOriginProd(isBetkwiffDomain, isCasinoDomain)
    : getWebAppOriginDev(isBetkwiffDomain, isCasinoDomain);

  switch (geoLocation) {
    case AcceptedCountries.INDIA:
      if (process.env.REACT_APP_ENV_INDIA) {
        return process.env.REACT_APP_ENV_INDIA;
      }
      return fallbackOrigin;
    default:
      return fallbackOrigin;
  }
};

const tryToAppendPostHogIdentifiersToURL = (url: string) => {
  let newUrl = url;

  const posthogDID = getPostHogDistinctId();
  if (posthogDID) {
    newUrl = appendSearchParamToURLString(newUrl, UrlSearchParamType.POSTHOG_DID, posthogDID);
  }
  const posthogSID = getPostHogSessionId();
  if (posthogSID) {
    newUrl = appendSearchParamToURLString(newUrl, UrlSearchParamType.POSTHOG_SID, posthogSID);
  }

  return newUrl;
};

export const appendSearchParamToURLString = (url: string, key: UrlSearchParamType, value: string) => {
  if (url.includes(`${key}=`)) {
    return url;
  }

  if (url.includes('?')) {
    return `${url}&${key}=${value}`;
  }

  return `${url}?${key}=${value}`;
};
