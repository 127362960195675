import { default as posthog, PostHogConfig } from 'posthog-js';
import { POST_HOG_EVENT } from '../data-types/posthog/posthogEnums';
import { PostHogIdentifiers } from '../data-types/posthog/posthogInterfaces';
import { POST_HOG_ENABLED } from './initialisation';

export const tryToInitiatePostHog = () => {
  if (POST_HOG_ENABLED) {
    const POST_HOG_KEY = process.env.REACT_APP_POST_HOG_KEY || 'INEXISTENT';
    const POST_HOG_HOST = process.env.REACT_APP_POST_HOG_HOST || 'INEXISTENT';

    const config: Partial<PostHogConfig> = {
      api_host: POST_HOG_HOST,
      autocapture: false,
      capture_pageview: false,
    };

    posthog.init(POST_HOG_KEY, config);
  }
};

export const postHogCapture = (eventName: POST_HOG_EVENT, identifiers: PostHogIdentifiers) => {
  if (POST_HOG_ENABLED) {
    posthog.capture(eventName, { ...identifiers });
  }
};

export const getPostHogSessionId = () => {
  if (POST_HOG_ENABLED) {
    return posthog.get_session_id();
  }

  return undefined;
};

export const getPostHogDistinctId = () => {
  if (POST_HOG_ENABLED) {
    return posthog.get_distinct_id();
  }

  return undefined;
};
